* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: "Josefin Sans", sans-serif;
}

.actives.active {
  font-weight: bold;
}
